import React  from 'react'; 
import { Link } from 'react-router-dom';
const Facility = () => { 
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Students</li>
                 <li>Facilities</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1> Facilities</h1>
                    </div>
                </div>
                <div className="row">
                     <div className="col-12">
                     <div className="row">
                     <div className="col-lg-3 col-md-4 col-sm-6">
                      <a data-magnify="gallery" data-src="" data-group="a" href="/Images/facility1.jpg">
                        <img className="img-fluid mx-auto d-block smartclass" src="/Images/facility1.jpg" alt="mgmb"/> 
                      </a>
                     </div>
                     <div className="col-lg-3 col-md-4 col-sm-6">
                      <a data-magnify="gallery" data-src="" data-group="a" href="/Images/facility2.jpg">
                        <img className="img-fluid mx-auto d-block smartclass" src="/Images/facility2.jpg" alt="mgmb"/> 
                      </a>
                     </div>
                     <div className="col-lg-3 col-md-4 col-sm-6">
                      <a data-magnify="gallery" data-src="" data-group="a" href="/Images/facility3.jpg">
                        <img className="img-fluid mx-auto d-block smartclass" src="/Images/facility3.jpg" alt="mgmb"/> 
                      </a>
                     </div>
                     <div className="col-lg-3 col-md-4 col-sm-6">
                      <a data-magnify="gallery" data-src="" data-group="a" href="/Images/facility4.jpg">
                        <img className="img-fluid mx-auto d-block smartclass" src="/Images/facility4.jpg" alt="mgmb"/> 
                      </a>
                     </div>
                      <div className="col-lg-3 col-md-4 col-sm-6">
                      <a data-magnify="gallery" data-src="" data-group="a" href="/Images/facility5.jpg">
                        <img className="img-fluid mx-auto d-block smartclass" src="/Images/facility5.jpg" alt="mgmb"/> 
                      </a>
                     </div>
               
                  </div> 
                       </div> 
                </div>
            </div>
        </div>
     </>
  )
}

export default Facility

