import React  from 'react'; 
import { Link } from 'react-router-dom';
const TeacherBlog = () => { 
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Activity</li>
                 <li>Teacher's Blog</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Teacher's Blog</h1>
                    </div>
                </div>
                <div className="row">
                     <div className="col-lg-12">
                         <div className="blogbox">
                             <div className="blogboximg">
                                 <img src="/Images/teacherblog.jpg" className="img-fluid" alt="" />
                              </div>
                             <div className="blogboxdesc">
                                  <h6>The Starting Point Of All Achievements Is DESIRE</h6>
                                  <div className="blogboxmsg"> <p> Positive thinking has the power to turn a hard day into a manageable one and a good day into an even better one. While a delicious treat is always a good idea, there is something about these inspiring words that feeds the soul in a way that even the best slice of Hummingbird Cake can’t do. So here are some positive thinking quotes that come from the heart that are sure to make a positive impact on your life.  </p>
                                  </div>
                                  <div className="blogboxinfo">
                                     <p className="blogname">MRS. Uma Tiwary <span> Principal</span></p>
                                     <p className="blogdate">Date : 26-10-2023</p>
                                  </div>
                             </div>
                         </div>
                         
 
                     </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default TeacherBlog

