import React, { useState } from "react"; 
import { Link } from 'react-router-dom'; 
import { form1 } from "../Service/Api";
import { getHeader } from "../config"
const Suggestion = () => { 
    const [formData, setFormData] = useState({
        name :"",  
        class :"",
        wardAndClass:"",
        date:"",
        message:"",
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const type = 'Admission'; 
        console.log('Submitting form with type:', type); // Add this line for debugging
        try {
            const { header } = getHeader();
            const response = await form1(formData, header, type); 
            console.log('Form submitted successfully', response);
            document.querySelector(".primary-alert").innerHTML = "<p>Your Form Submitted Successfully.</p>";
            document.querySelectorAll(".input, textarea").forEach(input => {
                input.value = "";
            });
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Contact Us</li>
                 <li>Suggestion</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Suggestion</h1>
                    </div>
                </div>
                <div className="row">
                     <div className="col-12">
                        <p>Do You Wish To Make A Suggestion In The Interest Of The School ? If Yes Fill Inthe Form Given Below :-</p>
                     <form onSubmit={handleSubmit} className="suggestionform">
                     <div className="formdiv">
                         <div className="form-group">
                             <label>Name<span>*</span></label>
                             <input type="text" name="name" className="form-control" value={formData.name} onChange={handleChange}/>
                         </div>
                         <div className="form-group">
                             <label>If you are a student, mention class:<span>*</span></label>
                             <input type="text" name="class" className="form-control" value={formData.class} onChange={handleChange}/>
                         </div>
                             <div className="form-group">
                                 <label>If you are a perent, mention your ward's name and class <span>*</span></label>
                                 <input type="text" name="wardAndClass" className="form-control" value={formData.wardAndClass} onChange={handleChange}/>
                             </div>
                              <div className="form-group">
                                     <label>Date<span>*</span></label>
                                     <input type="text" name="date" placeholder="DD/MM/YY" className="form-control" value={formData.date} onChange={handleChange}/>
                                 </div>
                                 <div className="form-group textarea">
                                     <label>Your Suggestion<span>*</span></label>
                                     <textarea  name="message" className="form-control"  value={formData.message} onChange={handleChange}></textarea>
                                 </div>
                             </div>
                             <input type="submit" value="Submit"/>
                              <div class="row"> 
                                <div className="col-lg-12 col-md-12">
                                    <div className='primary-alert'></div>
                                </div>
                            </div>
                          </form>
                     </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default Suggestion

