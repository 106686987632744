import React  from 'react'; 
import { Link } from 'react-router-dom';
const Careers = () => { 
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li>  
                 <li>Careers</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Careers</h1>
                    </div>
                </div>
                <div className="row">
                     <div className="col-12">
                     <img src="/Images/coming-soon.jpg" className="img-fluid mx-auto d-block cm" alt="HTS"/>
                    </div>
                </div> 
            </div>
        </div>
     </>
  )
}

export default Careers

