import React from 'react'
import { NavLink } from 'react-router-dom' 
import Highlights from'../Component/Highlights' 
const Header = () => {
  return (
        <> 
        <div className="header"> 
        <div className="logo">
            <NavLink to="/"><img src="/Images/logo.png" alt="Gandhi High School, Sidhi - Madhya Pradesh" className="img-fluid"/></NavLink>
        </div>
        <div className="header-nav">
        <Highlights/>
            <nav className="navbar navbar-expand-lg">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="main_nav">
                <ul className="navbar-nav"> 
                     <li className="nav-item dropdown"> 
                       <div className="nav-link" data-bs-toggle="dropdown">  About Us</div> 
                           <ul className="dropdown-menu fade-up"> 
                                <li><NavLink  className="dropdown-item" to="/PrincipalMessage">Principal Message</NavLink></li>
                                <li><NavLink  className="dropdown-item" to="/VisionMission">Vision Mission</NavLink></li>
                                <li><NavLink  className="dropdown-item" to="/HillTopFamily">Hill Top Family</NavLink></li>  
                           </ul>   
                    </li> 
                    <li className="nav-item dropdown">
                      <div className="nav-link" data-bs-toggle="dropdown"><NavLink  className="dropdown-item" to="/">Students </NavLink></div>  
                          <ul className="dropdown-menu fade-up">
                            <li><NavLink  className="dropdown-item" to="/Facility">Facility </NavLink></li>
                            <li><NavLink  className="dropdown-item" to="/CodeStudents">Code of Conduct for Students</NavLink></li>
                            <li><NavLink  className="dropdown-item" to="/StudentCouncil">Student Council</NavLink></li>
                            <li><NavLink  className="dropdown-item" to="/Exam">Exams</NavLink></li>
                            <li><NavLink  className="dropdown-item" to="/Attendance">Attendance</NavLink></li>
                            <li><NavLink  className="dropdown-item" to="/Withdrawals">Withdrawal</NavLink></li> 
                            <li><NavLink  className="dropdown-item" to="/Admission">Admission</NavLink></li> 
                        </ul>  
                    </li>
                    <li className="nav-item dropdown">
                       <div className="nav-link" data-bs-toggle="dropdown"><NavLink  className="dropdown-item" to="/"> Results</NavLink> </div>  
                           <ul className="dropdown-menu fade-up">
                            <li> <NavLink className="dropdown-item" to="/Toppers">ICSE Result</NavLink></li>
                            <li> <NavLink className="dropdown-item" to="/ToppersXII">ISC  Result</NavLink></li>  
                        </ul>  
                    </li>
                    <li className="nav-item dropdown"> 
                      <div className="nav-link" data-bs-toggle="dropdown"><NavLink  className="dropdown-item" to="/"> Activity</NavLink></div>  
                           <ul className="dropdown-menu fade-up">
                            <li> <NavLink className="dropdown-item" to="/Club">Club </NavLink></li>
                            <li> <NavLink className="dropdown-item" to="/Calendar">Calendar</NavLink></li> 
                            <li> <NavLink className="dropdown-item" to="/TeacherBlog">Teachers' Blog</NavLink></li> 
                            <li> <NavLink className="dropdown-item" to="/StudentBlog">Students' Blog</NavLink></li> 
                            <li> <NavLink className="dropdown-item" to="/Reflections">Reflections 2021</NavLink></li>  
                        </ul>  
                    </li>  
                    <li className="nav-item dropdown"> 
                      <div className="nav-link" data-bs-toggle="dropdown"><NavLink  className="dropdown-item" to="/">Gallery</NavLink></div>  
                            <ul className="dropdown-menu fade-up">
                            <li> <NavLink className="dropdown-item" to="/Gallery"> Gallery</NavLink></li> 
                            <li> <NavLink className="dropdown-item" to="/VideoGallery">Video Gallery</NavLink></li> 
                        </ul>  
                    </li>  
                    <li className="nav-item dropdown"> 
                      <div className="nav-link" data-bs-toggle="dropdown"><NavLink  className="dropdown-item" to="/">Policies</NavLink></div>  
                           <ul className="dropdown-menu fade-up">
                            <li> <NavLink className="dropdown-item" to="/CodeConduct">Code of Conduct</NavLink></li>
                            <li> <NavLink className="dropdown-item" to="/Fees">Fees</NavLink></li>  
                        </ul>   
                    </li>  
                    
                    <li className="nav-item dropdown"> 
                    <div className="nav-link" data-bs-toggle="dropdown"><NavLink  className="dropdown-item" to="/">Alumni</NavLink></div>  
                         <ul className="dropdown-menu fade-up">
                          
                          <li> <NavLink className="dropdown-item" to="#">Registration</NavLink></li>  
                      </ul>   
                  </li>  
                  <li className="nav-item"><NavLink className="dropdown-item" to="/Careers"> Careers </NavLink></li>  
                <li className="nav-item dropdown"> 
                <div className="nav-link" data-bs-toggle="dropdown"><NavLink  className="dropdown-item" to="/">Contact Us</NavLink></div>  
                     <ul className="dropdown-menu fade-up">
                      <li> <NavLink className="dropdown-item" to="/ContactUs">Contact</NavLink></li>
                      <li> <NavLink className="dropdown-item" to="/Suggestion">Suggestion</NavLink></li>  
                  </ul>   
                </li>   
                </ul>
                </div> 
           </nav>
        </div> 
    </div>
    </>
  )
}

export default Header
